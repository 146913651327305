.tui-image-editor {
  height: 100%;
}

.tui-image-editor-canvas-container {
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  overflow: hidden;
}

.tui-image-editor-controls {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: #fff;
}

.tui-image-editor-controls .submenu .menu-item:last-child {
  margin-right: 50px;
}

.tui-image-editor-controls .tui-colorpicker-container {
  display: inline-block;
}
