.message-container {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message-inner-container {
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 10px;
}

.participant-container {
  width: 100%;
  height: 15vh;
  overflow-x: auto;
  max-height: 53px;
  min-height: 50px;
  background-color: ghostwhite;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.message-box {
  background-color: ghostwhite;
  padding: 15px 20px;
  margin-bottom: 10px;
  box-shadow: 0px 2px 5px #c9c9c9;
}

.participant-card {
  background-color: white;
  padding: 0;
  height: auto;
  text-align: center;
  padding: 5px 10px 5px 10px;
  border-radius: 7px;
  box-shadow: 0px 2px 5px #c9c9c9;
  margin-left: 10px;
}

.message-heading {
  font-size: 14px;
  font-weight: normal;
  white-space: normal;
  line-height: 1.25;
  color: #5e5e5e;
}

.message-msg {
  font-size: 14px;
  font-weight: normal;
  white-space: pre-line;
  line-height: 1.25;
  margin-top: 7px;
  color: black;
}

.no-msg-available {
  width: 100%;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: bold;
}
.input-field {
  width: 90%;
  padding: 10px;
  border: 1px solid #7f7d7d;
}

.send-btn {
  width: 10%;
  height: 100%;
  max-height: 40px;
  border: none;
  background-color: #b40101;
  color: white;
  font-weight: bold;
}

.send-btn-disabled {
  width: 10%;
  height: 100%;
  max-height: 40px;
  border: none;
  background-color: gray;
  cursor: not-allowed;
  color: black;
  font-weight: bold;
}
